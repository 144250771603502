import Axios from "axios"

const API_KEY = process.env.GATSBY_GOOGLE_API_KEY
const SHEET_ID = process.env.GATSBY_GOOGLE_SHEET_ID

export interface Product {
    number: string
    titleFr: string
    titleEn: string
    upc: string 
    format: string
    imgUrl: string
    available: boolean
}

const mapValueToProduct = ([number, titleFr, titleEn, upc, format, imgUrl, available]) => ({
    number,
    titleFr,
    titleEn,
    upc, 
    format,
    imgUrl,
    available: available === "TRUE"
})

export const fetchProducts = async (): Promise<Product[]> => {
    const { data } = await Axios.get(`https://sheets.googleapis.com/v4/spreadsheets/${SHEET_ID}/values/Sheet1!A2:Z1000?key=${API_KEY}`)
    return data && data.values ? data.values.map(mapValueToProduct) : []
}