import React from "react"

interface Props {
    contrast?: boolean,
    dark?: boolean
}

const Icon = ({ contrast = false, dark = false }: Props) => {
    if (contrast) {
        return (
            <svg width="152px" height="40px" viewBox="0 0 152 40" version="1.1">
                <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                    <g id="products" transform="translate(-50.000000, -2459.000000)">
                        <g id="footer" transform="translate(0.000000, 2376.000000)">
                            <g id="logo-contrast" transform="translate(50.000000, 69.500000)">
                                <rect id="Rectangle" fill="#0D0D0D" fillRule="evenodd" x="0" y="37" width="152" height="16"></rect>
                                <text id="KPAJ-inc." fontFamily="AvenirNextCondensed-DemiBold, Avenir Next Condensed" fontSize="45" fontStyle="condensed" fontWeight="500" fill="#FFFFFF">
                                    <tspan x="10" y="45">KPAJ </tspan>
                                    <tspan x="104.5" y="45" fontSize="28">inc.</tspan>
                                </text>
                            </g>
                        </g>
                    </g>
                </g>
            </svg>
        );
    } else if (dark) {
        return (
            <svg width="152px" height="61px" viewBox="0 0 152 61" version="1.1">
                <g id="logo-dark" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                    <rect id="Rectangle" fill="#B13636" x="0" y="37" width="152" height="16"></rect>
                    <text id="KPAJ-inc." fontFamily="AvenirNextCondensed-DemiBold, Avenir Next Condensed" fontSize="45" fontStyle="condensed" fontWeight="500" fill="#0D0D0D">
                        <tspan x="10" y="45">KPAJ </tspan>
                        <tspan x="104.5" y="45" font-size="28">inc.</tspan>
                    </text>
                </g>
            </svg>
        )
    } else {
        return (
            <svg width="152px" height="40px" viewBox="0 0 152 40" version="1.1">
                <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                    <g id="products" transform="translate(-50.000000, -33.000000)">
                        <g id="header">
                            <g id="nav">
                                <g id="logo" transform="translate(50.000000, 20.000000)">
                                    <rect id="Rectangle" fill="#B13636" fillRule="evenodd" x="0" y="37" width="152" height="16"></rect>
                                    <text id="KPAJ-inc." fontFamily="AvenirNextCondensed-DemiBold, Avenir Next Condensed" fontSize="45" fontStyle="condensed" fontWeight="500" fill="#FFFFFF">
                                        <tspan x="10" y="45">KPAJ </tspan>
                                        <tspan x="104.5" y="45" fontSize="28">inc.</tspan>
                                    </text>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </svg>
        );
    }
}

export default Icon


